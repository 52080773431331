import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Card,
    Form,
    Button,
    InputGroup,
    FormControl,
    DropdownButton,
    Dropdown,
    Table
} from 'react-bootstrap'

import { CONFIG } from '../../config/constant'
import { BASE } from '../../basedata/constants'
import el from 'date-fns/esm/locale/el/index.js'

interface Props {
    devices: any,
    totalReference: any
}

export function GateCount({ devices, totalReference }: Props) {

    return (
        <Row>        {devices &&
            devices.map((terminal: any, index: any) => {
                return (<Col key={index} xl={3}>
                    <Card className={`game-label ${Number(totalReference) != Number(terminal.count) ? 'danger' : 'default'}`}>
                        <h6 className="gate-name">{terminal.ip.split('.').pop()}</h6>{terminal.count}
                    </Card>
                </Col>)
            })}</Row>
    )
}
